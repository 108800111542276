import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://afc04dbf255d37b0840183c89396dfee@o4507629142278144.ingest.de.sentry.io/4507646489133136",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});
import "@astrojs/vercel/speed-insights"